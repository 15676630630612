<!-- 共享 xx 至资料库弹窗内容 -->
<template>
  <div :ref="itRef" class="shared-material">
    <p>{{ askTitle }}</p>
    <vh-checkbox v-model="isShared">共享到资料管理</vh-checkbox>
  </div>
</template>
<script>
  export default {
    name: 'SharedMaterial',
    props: {
      itRef: String,
      name: String,
      title: String,
      // 默认勾选上
      checked: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      askTitle() {
        return this.title ? this.title : `保存${this.name}同时共享至资料管理，便于其他活动使用？`;
      }
    },
    data() {
      return {
        isShared: this.checked
      };
    }
  };
</script>
<style lang="less">
  .shared-material {
    p {
      line-height: 3;
    }
  }
</style>
