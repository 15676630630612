
import SharedMaterial from './SharedMaterial.vue';

export default async function useSharedMaterial(h, name, options) {
  if (typeof name === 'object') {
    options = name;
    name = '';
  }
  let isShared = false;
  const myRef = `myRef${Math.floor(Math.random() * (10000)) + 1}`;
  await Vue.prototype.$vhMsgbox({
    title: '提示',
    closeOnClickModal: false,
    showCancelButton: true,
    customClass: 'vhc-message-box',
    message: h(SharedMaterial, {
      props: {
        itRef: myRef,
        name: name,
        title: options?.title || '', // 自定义标题，优先级高于name
        checked: options?.checked  // 默认是否勾选
      }
    }),
    beforeClose(action, instance, done) {
      if (action == 'confirm') {
        const that = instance.$children.find(item => !!item?.$refs[myRef]);
        if (that) isShared = that.isShared;
      }
      done();
    }
  }).catch(() => { });
  return isShared;
}